import React, { useEffect, useState } from 'react';
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';



const PageSolicitarTarjetaDeCreditoSantander = props => {
  
  // const [adsenseLoaded, setAdsenseLoaded] = useState(false);
  // const [contentLoaded, setContentLoaded] = useState(false);

  // useEffect(() => {
  //   setAdsenseLoaded(true);
  //   console.log('cargando')
  //   const timer = setTimeout(() => {
  //     console.log('cargando2')
  //     setContentLoaded(true);
  //   }, 2000); // 3 seconds delay

  //   return () => clearTimeout(timer);
  // }, []);

  // if (!contentLoaded) {
  //   return (
  //     <div style={{ 
  //       textAlign: 'center', 
  //       padding: '50px', 
  //       fontWeight: '800', 
  //       color: 'gray', 
  //       fontFamily: 'helvetica', 
  //       backgroundColor: 'rgba(226, 226, 226, 0.5)', // Gray overlay
  //       height: '100vh', 
  //       display: 'flex', 
  //       alignItems: 'center', 
  //       justifyContent: 'center' 
  //     }}>
  //       <p>Cargando...</p>
  //     </div>
  //   );
  // }


  return (
    <Layout {...props}>
      <Seo title='Prestamos-Santander' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
     <Stack>
     </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Descubrí los Beneficios y Cómo Solicitar tu Tarjeta de Crédito Santander💸💳'
          subheader='Guía Completa para Solicitar tu Tarjeta de Crédito Santander: Beneficios, Promociones y Requisitos'
        />
      </Stack>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $650.000 💰 </Button>
        </Link>
        <Link to="/credito-hipotecario">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Sacá tu Crédito Hipotecario 💸🏡 </Button>
        </Link>
        <Link to="/solicitar-tarjeta-de-credito-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Solicita tu Tarjeta de Crédito BBVA 🤑 </Button>
        </Link>
        <Link to="/prestamos-personales">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicitá tu préstamo acá 💰 </Button>
        </Link>
      </div>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
         La tarjeta de crédito Santander es una excelente opción para aquellos que buscan flexibilidad y beneficios exclusivos. 
         Si estás considerando solicitar una tarjeta de crédito Santander, aquí te contamos todo lo que necesitas saber para tomar la mejor decisión.
         <br>
         <br>
         <b>Beneficios de la tarjeta de crédito Santander</b>
            <ul>
              <li>Premios por consumo: Cada vez que uses tu tarjeta de crédito Santander, acumulas puntos que puedes canjear por descuentos y premios.</li>
              <li>Música y series: Disfruta de tus suscripciones favoritas con la opción de pagar tus servicios de streaming.
              </li>
              <li>Pagos con celular: Usa tu tarjeta de crédito Santander para pagar de manera fácil y segura a través de aplicaciones móviles como MODO, GPay, y Pay.
              </li>
              <li>Ahorros en supermercados y combustible: Obtén hasta $50,000 en ahorros durante los primeros seis meses.
              </li>
              <li>Entrega a domicilio: Paga tus compras online y recibe tus productos en la puerta de tu casa.
              </li>
              <li>Promociones exclusivas: Beneficios y descuentos especiales en diversos establecimientos.
              </li>
            </ul>



          '
        />
      </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
        <b>Requisitos para solicitar la tarjeta de crédito Santander</b>
        <br>
        Para solicitar la tarjeta de crédito Santander, debes cumplir con ciertos requisitos que aseguran tu elegibilidad. Aquí te los detallamos:
          <ul>
	          <li><b>Documento de identidad:</b> Necesitarás tu DNI para completar el proceso de solicitud.</li>
	          <li><b>Proceso rápido:</b> Completa tu solicitud en tan solo tres minutos, estés donde estés.</li>
	          <li><b>Promociones:</b> Aprovecha la promoción de seis meses gratis sin cargo por mantenimiento de cuenta.</li>
          </ul>
          <br><br>
<b>Cómo solicitar la tarjeta de crédito Santander</b>
Solicitar la tarjeta de crédito Santander es un proceso sencillo y rápido. Aquí te mostramos cómo hacerlo:
<br><br>
<ul>
<li><b>Ingresa al sitio web o app de Santander:</b> Visita la página oficial o descarga la aplicación para empezar.</li>
<li><b>Selecciona tu tarjeta:</b> Escoge entre Visa, Mastercard o American Express.</li>
<li><b>Completa el formulario:</b> Llena el formulario con tus datos personales y escanea tu DNI.</li>
<li><b>Recibe tu tarjeta:</b> Una vez aprobada, recibirás tu tarjeta en la dirección que indiques.</li>
</ul>
<br><br>
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Link to="https://www.anses.blog/descubre-los-beneficios-de-la-tarjeta-de-credito-bbva-visa-platinum/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'omegaDark' , fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Descubre los Beneficios de la Tarjeta de Crédito BBVA Visa Platinum 💳🔥
          </Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
<b>Opiniones sobre la tarjeta de crédito Santander</b>
Las opiniones sobre la tarjeta de crédito Santander son mayormente positivas. Los usuarios destacan la facilidad para solicitarla y los beneficios que ofrece, especialmente los descuentos y promociones.
<br><br>
<b>Límites y condiciones</b>
Es importante conocer los límites y condiciones de tu tarjeta de crédito Santander para utilizarla de manera efectiva. Algunos de los aspectos clave incluyen:
<ul>	
<li><b>Límites de crédito:</b> Dependiendo de tu perfil crediticio, se establecerá un límite de crédito adecuado.</li>
<li><b>Condiciones de uso:</b> Consulta las condiciones de uso para conocer todos los detalles sobre comisiones y tasas de interés.</li>
<li><b>Seguridad:</b> Disfruta de la tranquilidad de contar con medidas de seguridad avanzadas para proteger tus transacciones.</li>
</ul>
<br><br>
<b>Más beneficios y promociones</b>
Además de los beneficios ya mencionados, la tarjeta de crédito Santander ofrece promociones continuas que puedes aprovechar para maximizar tus ahorros y disfrutar de experiencias exclusivas. Mantente informado visitando regularmente el sitio web de Santander.
<br><br>
En resumen, la tarjeta de crédito Santander es una opción conveniente y llena de ventajas para aquellos que buscan flexibilidad y beneficios exclusivos. No esperes más y solicita tu tarjeta de crédito Santander hoy mismo para comenzar a disfrutar de todos sus beneficios.
<br><br>
<b>Conclusión</b>
Solicitar una tarjeta de crédito Santander es un proceso rápido y sencillo que te permite acceder a una amplia gama de beneficios y promociones exclusivas. Si estás buscando una tarjeta que te ofrezca flexibilidad y recompensas por tus consumos, la tarjeta de crédito Santander es la opción ideal. No dudes en solicitar tu tarjeta de crédito Santander y comenzar a disfrutar de todas sus ventajas.


          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/llegaron-los-creditos-hipotecarios-de-bbva-y-galicia-como-son-y-como-solicitar/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'omegaDark' , fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Llegaron los Créditos Hipotecarios de BBVA y Galicia: ¿Cómo Son y Cómo Solicitar?🏡</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PageSolicitarTarjetaDeCreditoSantander
